<template>
	<!-- 议价单详情 -->
	<div :class="themeClass" class="bg-f8f9fe width-fill">
		<div class="top-breadcrumb">
			<div class="breadcrumbDiv margin-t-20 margin-b-10">
				<div class="flex-row font-color-999">
					<span class="margin-top-3px">您的位置：</span>
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/businessBuy' }">企业购</el-breadcrumb-item>
						<el-breadcrumb-item>
							<div class="color-theme">议价详情</div>
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
		</div>

		<div class="yijia-box bg-fff">
			<div class="font-size18 font-weight700 padding-lt-13">议价详情</div>
			<div class="padding-lr-13 margin-t-10">
				<div class="yijia-step bg-fff padding-t-15">
					<div v-if="DetailData.fApproveStatus==1">
						<el-steps :active="active" :align-center="true" finish-status="success">
							<el-step title="议价申请"></el-step>
							<el-step title="审核处理中"></el-step>
							<el-step title="待确认"></el-step>
							<el-step title="生效"></el-step>
						</el-steps>
					</div>
					<div v-else-if="DetailData.fApproveStatus==255">
						<el-steps :active="active" :align-center="true" finish-status="success">
							<el-step title="议价申请"></el-step>
							<el-step title="审核不通过"></el-step>
						</el-steps>
					</div>
					<div v-else-if="DetailData.fApproveStatus==254&&DetailData.fCustomerApproveStatus==0">
						<el-steps :active="active" :align-center="true" finish-status="success">
							<el-step title="议价申请"></el-step>
							<el-step title="审核通过"></el-step>
							<el-step title="待确认"></el-step>
							<el-step title="生效"></el-step>
						</el-steps>
					</div>
					<div v-else-if="DetailData.fApproveStatus==254&&DetailData.fCustomerApproveStatus==2">
						<el-steps :active="active" :align-center="true" finish-status="success">
							<el-step title="议价申请"></el-step>
							<el-step title="审核通过"></el-step>
							<el-step title="确认不同意"></el-step>
						</el-steps>
					</div>
					<div v-else>
						<el-steps :active="active" :align-center="true" finish-status="success">
							<el-step title="议价申请"></el-step>
							<el-step title="审核通过"></el-step>
							<el-step title="确认同意"></el-step>
							<el-step title="生效"></el-step>
						</el-steps>
					</div>
				</div>
			</div>
			
			<div class="padding-lr-13 margin-t-10 margin-b-52">
				<div class="item-title flex-row-space-between">
					<div class="shopname flex-row-align-center">
						<el-image style="width: 12px; height: 12px" :src="DetailData.fShopLogoPath" :fit="fill"></el-image>
						<div class="font-size12 margin-l-5">
							{{ DetailData.fShopName }}
						</div>
						<div class="box-four-content-type font-size12 margin-l-10 font-color-fff" v-if="DetailData.fShopTypeName == '自营'">
							{{ DetailData.fShopTypeName }}
						</div>
					</div>
					<div :class="
              DetailData.fApproveStatus == 1
                ? 'font-color-FF0033'
                : 'color-theme'
            ">
						{{ DetailData.fApproveStatusName }}
					</div>
				</div>
				<div class="item-content bg-fff" v-for="(item, index) in DetailData.details" :key="index">
					<div class="flex-row-space-between">
						<div class="flex-row-align-center item-content-left hide-text">
							<div class="goods-pic flex-row-align-center text-center">
								<img :src="item.fPicturePath" alt="" width="100%" />
							</div>
							<div>
								<div class="productionPlant font-size13">
									{{ item.fGoodsFullName }}
								</div>
								<div class="flex-row font-size12 margin5-0">
									<div class="delivery color-theme border-color-theme text-center">
										{{ item.fDelivery }}
									</div>
								</div>
							</div>
						</div>
						<div class="item-content-right flex-row">
							<div class="flex-row">
								<!-- <div class="num commonBox line-height23">
									<div class="margin25-auto">
										<span>商城价</span>
										<div class="font-weight700">
											x&nbsp;{{ item.purchaseQuantity }}
										</div>
									</div>
								</div> -->
								<div class="payment commonBox line-height90" :class="item.fDetailStatus == 1 ? 'font-color-FF0033' : ''">
									<!-- {{ item.fSaleAmount }}吨 -->
								</div>
								<div class="payment commonBox line-height23" :class="[
                    item.fDetailStatus == 0 ? 'color00CC0' : '',
                    item.fDetailStatus == 1 ? 'font-color-FF0033' : ''
                  ]">
									<div class="margin25-auto">
										<span class="font-size12"  v-if="DetailData.fApproveStatus !=1">{{item.fDetailStatusName}}</span>
										<span v-if="DetailData.fApproveStatus==1 &&  item.fDetailStatus == 0" class="font-color-FF0033 font-size12">审核中</span>
										<div class="font-weight700 font-size13">
											¥{{ item.fApplyGoodsPrice }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="font-size15 font-weight700 margin-l-13 margin-tb-22">
					详情信息
				</div>
				<div>
					<el-form ref="DetailData" :model="DetailData" label-width="130px" :label-position="labelPosition">
						<el-form-item label="询价单号：">
							<span class="font-size13">{{ DetailData.fBillNumber }} </span>
						</el-form-item>
						<el-form-item label="申请时间：">
							<span class="font-size13">{{ DetailData.fCreateTime }}</span>
						</el-form-item>
						<el-form-item label="价格开始时间：">
							<span class="font-size13">{{ DetailData.fBeginTime }}</span>
						</el-form-item>
						<el-form-item label="价格结束时间：">
							<span class="font-size13">{{ DetailData.fEndTime }}</span>
						</el-form-item>
						<el-form-item label="付款方式">
							<span class="font-size13">{{ DetailData.fCapTypeS }}</span>
						</el-form-item>
						<el-form-item label="备注：">
							<span class="font-size13">{{ DetailData.fRemark }}</span>
						</el-form-item>
					</el-form>
				</div>
				<div class="padding-b-35 flex-row margin-t-30 margin-l-13 pointer">
					<div v-if="DetailData.fApproveStatus==254&&DetailData.fCustomerApproveStatus==0" class="downlode_text margin-r-13 text-center background-color-theme font-color-fff font-size14" @click="agree()">
						确认并同意议价
					</div>
					<div v-if="DetailData.fApproveStatus==254&&DetailData.fCustomerApproveStatus==0" class="downlode_text text-center background-color-theme font-color-fff font-size14" @click="disagree()">
						不同意议价
					</div>
					<div v-else-if="DetailData.fApproveStatus == 255" class="downlode_text text-center border-color-theme color-theme font-size14" @click="afreshBuyingPrice()">
						重新议价
					</div>
					<div v-else-if="DetailData.fApproveStatus==254&&DetailData.fCustomerApproveStatus==1&&DetailData.fStatus==1">
						<div  class="downlode_text text-center border-color-theme color-theme font-size14" style="display: inline-block;" @click="downloadBargainingList()">
							下载价格确认单
						</div>
						<div  class="downlode_text text-center border-color-theme color-theme font-size14" style="display: inline-block;margin-left: 10px;" @click="openpdf()">
							查看价格确认单
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				active:1,
				fill: "cover",
				payStatus: 0,
				shopName: "乌鲁木齐市啦啦建材有限责任公司",
				tag: "自营",
				DetailData: {},
				labelPosition: "right",
				detailId: "",
			};
		},
		created() {
			if (this.$route.params.id === null || this.$route.params.id === undefined) {
				//如id为空，则不请求数据
				this.$message.error("请检查参数是否正确");
				return;
			} else {
				//否则请求数据
				this.detailId = this.$route.params.id;
				this.getList();
			}
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		watch: {
			$route(to, from) {
				//监听路由是否变化
				if (to.params.id != from.params.id && to.params.id != null) {
					//id不为空才请求数据
					this.detailId = to.params.id;
					this.getList(); //调接口，请求数据
				}
			},
		},
		methods: {
			getList() {
				this.ApiRequestPost("/api/mall/ebsale/b2b-inquiry-apply/get", {
					id: this.detailId,
				}).then(
					(result) => {
						let curObj = result.obj;
						curObj.fCreateTime = this.getTime(curObj.fCreateTime);
						curObj.fModifyTime = this.getTime(curObj.fModifyTime);
						curObj.fBeginTime = this.getTimeMin(curObj.fBeginTime);
						curObj.fEndTime = this.getTimeMin(curObj.fEndTime);
						this.DetailData = curObj;
						this.active=this.DetailData.fCustomerApproveStatus
						if(this.DetailData.fApproveStatus==1||this.DetailData.fApproveStatus==255){
							this.active=2
						}else if(this.DetailData.fApproveStatus==254&&this.DetailData.fCustomerApproveStatus==0){
							this.active=3
						}else if(this.DetailData.fApproveStatus==254&&this.DetailData.fCustomerApproveStatus==2){
							this.active=3
						}else if(this.DetailData.fApproveStatus==254&&this.DetailData.fCustomerApproveStatus==1&&this.DetailData.fStatus==1){
							this.active=4
						}
						console.log(this.list, "议价单详情this.list");
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			getTimeHours(time){
			    return time.toString().substring(0,13).replace("T"," ")+"时";
			},
			// 格式化时间（分钟）
			getTimeMin(time) {
				return time.toString().substring(0, 16).replace("T", " ") ;
			},
			//客户同意议价结果
			agree() {
				this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/confirm", {
					fInquiryApplyID: this.detailId
				}).then(
					(res) => {
						this.getList(); //全部议价
					},
					(error) => {}
				);
			},
			//客户不同意议价结果
			disagree() {
				this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/disagree", {
					fInquiryApplyID: this.detailId
				}).then(
					(res) => {
						this.getList(); //全部议价
					},
					(error) => {}
				);
			},
			//重新议价
			afreshBuyingPrice() {
				this.$router.push({
					name: "applicationPricetab",
					query: {
						id: this.detailId,
						edit: true,
					},
				});
			},
			// 下载议价单
			downloadBargainingList(){
				this.ApiRequestPost("api/mall/ebsale/b2b-inquiry-apply/get-bargaining-list", {
					fInquiryApplyID: this.detailId
				}).then(
					(res) => {
						console.log(res.obj);
						window.open(res.obj,'_blank')
						//this.getList(); //全部议价
					},
					(error) => {
						console.log(JSON.stringify(error));
					}
				);
			},
			openpdf(){
				this.ApiRequestPost("api/mall/ebsale/b2b-inquiry-apply/get-bargaining-list", {
					fInquiryApplyID: this.detailId
				}).then(
					(res) => {
						console.log(res.obj);
						window.open(res.obj,'_blank')
						//this.getList(); //全部议价
					},
					(error) => {
						console.log(JSON.stringify(error));
					}
				);
			},
		}

	};
</script>

<style scoped lang="scss">
.downlode_text{
	width: 235px;
	height: 48px;
	line-height: 48px;
}
.yijia-step{
	height: 111px;
	border: 1px solid #f2f4fd;
	box-sizing: border-box;
}
.margin-auto{
	margin: auto;
}
	.margin-l-5 {
		margin-left: 5px;
	}

	.top-breadcrumb {
		margin: 0 auto;
		width: 1100px;
	}

	.color00CC0 {
		color: #00cc00;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.bg-f8f9fe {
		background: #f8f9fe;
	}

	.padding-lt-13 {
		padding: 20px 0 0 13px;
	}

	.yijia-box {
		width: 1100px;
		min-height: 700px;
		// background: #f8f9fe;
		margin-bottom: 100px;
		margin: 0 auto 15px auto;
	}

	.goods-pic {
		width: 65px;
		height: 65px;
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
		margin-right: 19px;
		margin-left: 15px;
		overflow: hidden;
	}

	.yijia-content {
		width: 1100px;
		background: #f8f9fe;
		margin: 0 auto 42px auto;
	}

	.font-color-FF0033 {
		color: #ff0033;
	}

	.padding20-t-lr {
		padding: 20px 20px 50px 20px;
	}

	.margin-top-3px {
		margin-top: -3px;
	}

	.margin25-auto {
		margin: 25px auto;
	}
	.margin-tb-22{
		margin-top: 22px;
		margin-bottom: 17px;
	}
	.margin-b-52{
		margin-bottom: 52px;
	}
	.item-title {
		box-sizing: border-box;
		height: 35px;
		padding: 0 13px;
		line-height: 35px;
		background: #f2f4fd;

		.orderID {
			margin-left: 15px;
		}

		.shopname {
			.width-500 {
				padding-left: 5px;
				width: 350px;
			}
		}
	}

	.margin5-0 {
		margin: 5px 0;
	}

	::v-deep .el-breadcrumb__inner.is-link {
		font-weight: 400;
		color: #999999;
	}

	::v-deep .el-breadcrumb__inner {
		color: #999999;
	}

	.box-four-content-type {
		min-width: 20px;
		height: 17px;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		padding: 0 4px;
		line-height: 18px;
		border-radius: 2px;
		margin-right: 5px;
	}

	.item-content {
		border: 1px solid #f2f4fd;

		.item-content-left {
			max-width: 700px;
			height: 90px;
		}

		.goods-pic {
			width: 65px;
			height: 65px;
			background: #ffffff;
			border-radius: 10px;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
			margin-right: 19px;
			margin-left: 15px;
		}
	}

	.delivery {
		width: max-content;
		padding: 0 5px;
		height: 15px;
		line-height: 15px;
	}

	.engineering {
		color: #aeaeae;
	}

	.line-height23 {
		line-height: 23px;
	}

	.line-height90 {
		line-height: 90px;
	}

	.commonBox {
		width: 123px;
		height: 90px;
		text-align: center;
		border-left: 1px solid #f2f4fd;
	}

	.downlode_btn {
		width: 235px;
		line-height: 45px;
		margin: 30px 20px 50px 20px;
	}

	.downlode_btn:hover {
		cursor: pointer;
	}

	::v-deep .el-form-item {
		margin-bottom: 0;
	}

	::v-deep .el-form-item__label,
	::v-deep .el-form-item__content {
		height: 27px;
		line-height: 27px;
		font-size: 13px;
	}
	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		font-size: 14px;
		// color: #999999;
	}

	::v-deep .el-step__title.is-success {
		font-weight: 400;
		font-size: 14px;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */
</style>
